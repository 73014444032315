import dayjs from 'dayjs';
import {
  axiosWithPayloadContext,
  config,
  convertArrayToFirstElement,
  createHeaders,
  ListOwnerLeadReferralLinksQuery,
  ListOwnerLeadWorkflowItemsByPmQuery,
  OwnerLeadReferralLink,
  OwnerLeadWorkflowItem,
  queryGraphQL,
  useAuth,
  WhatAreYouLookingFor,
} from 'lib';
import { placeSchema } from 'ui';
import { z } from 'zod';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useMutation, useQuery } from '@tanstack/react-query';

import { QueryKey } from '../../types/enums';
import { listOwnerLeadReferralLinks, listOwnerLeads } from '../graphql/queries';

const whatAreYouLookingForOptions = [
  WhatAreYouLookingFor.UNKNOWN,
  WhatAreYouLookingFor.CHANGE_PM,
  WhatAreYouLookingFor.BUY_INVESTMENT_PROPERTY,
  WhatAreYouLookingFor.STOP_SELF_MANAGING,
] as const;

export const addLeadFormSchema = z.object({
  email: z.string().email('This is not a valid email').min(1),
  firstName: z.string().trim().min(1, 'First name is required'),
  lastName: z.string().trim().min(1, 'Last name is required'),
  phone: z.string().trim().min(1, 'Phone number is required'),
  numOfProperties: z.coerce.number().min(1, 'At least one property is required'),
  addresses: z.array(placeSchema.optional().nullable()),
  requestRentalAnalysis: z.boolean().optional(),
  whatAreYouLookingFor: z.enum(whatAreYouLookingForOptions).optional(),
});

export type AddLeadData = z.infer<typeof addLeadFormSchema>;

export const useCreateLead = () => {
  const { user, getAccessTokenSilently } = useAuth();

  return useMutation(async (data: AddLeadData) => {
    const token = await getAccessTokenSilently();

    const addresses = data.addresses.filter((address) => !(!address || address.description === '')).map((address) => {
      // this will never happen, this is to appease typescript gods
      if (!address) {
        return '';
      }
      return address.description;
    });

    const sendData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      numOfDoors: data.numOfProperties,
      getRentalAnalysis: data.requestRentalAnalysis,
      propertyAddress: addresses,
      whatAreYouLookingFor: data.whatAreYouLookingFor,
      pm: user?.pm || '',
    };

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/owner-lead`,
      method: 'POST',
      headers: createHeaders(token),
      data: sendData,
    });
  });
};

export const useListOwnerLeads = () => {
  const { user, getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.OWNER_LEADS], async (): Promise<OwnerLeadWorkflowItem[]> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listOwnerLeads,
      authToken: token,
      variables: { pm: user?.pm ?? '' },
    }) as GraphQLResult<ListOwnerLeadWorkflowItemsByPmQuery>;

    if (!res.data?.listOwnerLeadWorkflowItemsByPm) {
      return [];
    }

    const ownerLeadWorkflowItems = (res.data.listOwnerLeadWorkflowItemsByPm.items as OwnerLeadWorkflowItem[])
      .sort((a: OwnerLeadWorkflowItem, b: OwnerLeadWorkflowItem) => dayjs(b.updatedTime).diff(dayjs(a.updatedTime)));
    return ownerLeadWorkflowItems as OwnerLeadWorkflowItem[];
  }, { enabled: !!user?.id });
};

export const useGetOwnerLeadsReferralLink = () => {
  const { user, getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.OWNER_LEADS_REFERRAL_LINKS], async (): Promise<OwnerLeadReferralLink | null> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listOwnerLeadReferralLinks,
      authToken: token,
      variables: {
        ownerId: user?.id,
      },
    }) as GraphQLResult<ListOwnerLeadReferralLinksQuery>;

    if (!res.data?.listOwnerLeadReferralLinks) {
      return null;
    }

    const ownerLeadReferralLinks = (res.data.listOwnerLeadReferralLinks.items as OwnerLeadReferralLink[])
      .sort((a: OwnerLeadReferralLink, b: OwnerLeadReferralLink) => dayjs(b.updatedTime).diff(dayjs(a.updatedTime)));

    const referralLink = convertArrayToFirstElement<OwnerLeadReferralLink>({
      items: ownerLeadReferralLinks,
    });
    return referralLink as OwnerLeadReferralLink;
  }, { enabled: !!user?.id });
};
