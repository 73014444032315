import { useEffect } from 'react';

import {
  buildPartnerReferralLink, OwnerReferralsIncentive, useAnalytics, useAuth,
} from 'lib';
import { Controller, useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  BoldTypography, CopyIcon, InvertedButton, Spinner, useLabels,
} from 'ui';
// eslint-disable-next-line import/no-extraneous-dependencies
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { CreateOwnerLeadData, createOwnerLeadSchema, useCreateOwnerLead } from '../../api/ownerLeads';

export const ReferAnOwnerDialog = ({
  open,
  onClose,
  b2cReferralIncentive,
}: {
  open: boolean,
  onClose: () => void,
  b2cReferralIncentive: OwnerReferralsIncentive,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const { user } = useAuth();
  const ownerReferralIncentiveToNumberOfMonths = {
    [OwnerReferralsIncentive.NO_INCENTIVE]: 0,
    [OwnerReferralsIncentive.ZERO_MGMT_FEE_ONE_MONTH]: 1,
    [OwnerReferralsIncentive.ZERO_MGMT_FEE_TWO_MONTHS]: 2,
    [OwnerReferralsIncentive.ZERO_MGMT_FEE_THREE_MONTHS]: 3,
  };

  const getTitleDescriptionText = (): string => {
    const baseText = 'Refer a client to your PM';
    if (b2cReferralIncentive === OwnerReferralsIncentive.NO_INCENTIVE) {
      return baseText;
    }

    const monthOrMonths = b2cReferralIncentive === OwnerReferralsIncentive.ZERO_MGMT_FEE_ONE_MONTH ? 'month' : 'months';

    return `${baseText} and earn ${ownerReferralIncentiveToNumberOfMonths[b2cReferralIncentive].toString()
    } ${monthOrMonths} of management fee`;
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<CreateOwnerLeadData>({
    resolver: zodResolver(createOwnerLeadSchema),
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
  });
  const { mutateAsync: createOwnerLead, isLoading: isCreatingOwnerLead } = useCreateOwnerLead();

  const handleClickClose = (e: any, reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick') => {
    if (reason === 'backdropClick') return;

    analytics.track('Button Clicked', {
      buttonName: 'Close',
    });

    onClose();
  };

  const linkID = encodeURI(user?.email ?? '');
  if (linkID === '') {
    return '';
  }

  const isLoading = isCreatingOwnerLead;
  const link = buildPartnerReferralLink(linkID);

  const handleCreateOwnerLead = async (data: CreateOwnerLeadData) => {
    try {
      await createOwnerLead({
        ...data,
      });

      toast.success(l['referAnOwner.ownerLead.ownerLeadCreated']);
      onClose();
    } catch (e) {
      console.error(e);

      toast.error(l['error.unknownError']);
      onClose();
    }
  };

  const handleClickAdd = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Create Owner Lead',
    });

    await handleSubmit(handleCreateOwnerLead)();
  };

  useEffect(() => {
    if (!open) reset();
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClickClose}
      fullScreen={isLowerThanMd}
      fullWidth
      maxWidth="sm"
      disableEscapeKeyDown
      sx={{
        zIndex: 2000,
      }}
    >
      <DialogTitle sx={{ py: 2, pt: 10 }}>
        <Stack alignItems="center">
          <IconButton
            sx={{
              position: 'absolute',
              top: '20px',
              right: '20px',
            }}
            onClick={() => handleClickClose({}, 'buttonClick')}
          >
            <MdClose />
          </IconButton>
          <BoldTypography variant="h6">
            {l['referAnOwner.referAndEarn']}
          </BoldTypography>
          <Typography variant="body3">
            {getTitleDescriptionText()}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: `${theme.spacing(8)} !important` }}>
        <Stack gap={4} width="100%">
          <BoldTypography variant="body1">{l['referAnOwner.shareAndInvite']}</BoldTypography>
          <Stack width="100%" direction="row" gap={4} alignItems="center" flexWrap="wrap">
            <TextField
              sx={{
                flexGrow: 1,
              }}
              size="small"
              disabled
              value={link}
            />
            <CopyLinkButton link={link} />
          </Stack>
          <Stack gap={4}>
            <BoldTypography pt={4} variant="body1">{l['referAnOwner.sendToFriend']}</BoldTypography>
            <Stack direction="row" gap={4} justifyContent="space-between" width="100%" flexWrap="wrap">
              <Stack gap={4} flexGrow={1}>
                <BoldTypography variant="body2">{l['referAnOwner.ownerLead.firstName']}</BoldTypography>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      placeholder={l['referAnOwner.ownerLead.firstName']}
                      fullWidth
                      autoComplete="off"
                      variant="outlined"
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                    />
                  )}
                />
              </Stack>
              <Stack gap={4} flexGrow={1}>
                <BoldTypography variant="body2">{l['referAnOwner.ownerLead.lastName']}</BoldTypography>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      placeholder={l['referAnOwner.ownerLead.lastName']}
                      fullWidth
                      autoComplete="off"
                      variant="outlined"
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                    />
                  )}
                />
              </Stack>

            </Stack>
            <Stack gap={4}>
              <BoldTypography variant="body2">{l['referAnOwner.ownerLead.email']}</BoldTypography>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    placeholder={l['referAnOwner.ownerLead.email']}
                    autoComplete="off"
                    type="email"
                    variant="outlined"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Stack>

      </DialogContent>
      <DialogActions sx={{ py: 3, px: 6, pb: 8 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-around" width="100%" pt={3} gap={2}>
          <InvertedButton
            size="medium"
            onClick={handleClickAdd}
            disabled={isLoading}
          >
            {(isLoading) ? (
              <>
                                &nbsp;
                <Spinner size={20} />
              </>
            ) : l.invite}
          </InvertedButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export const CopyLinkButton = ({ link }: { link: string }) => {
  const theme = useTheme();
  const l = useLabels();
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link).then(() => {
      toast.success(l['referral-partners.copyText']);
    }).catch(() => {
      toast.error(l['error.unknownError']);
    });
  };

  const width = isLowerThanMd ? '100%' : '40%';

  return (
    <Button fullWidth={isLowerThanMd} sx={{ width }} variant="outlined" onClick={copyToClipboard} startIcon={<CopyIcon />}>
      {l['referral-partners.copyLink']}
    </Button>
  );
};
