import { Fragment } from 'react';

import { BoldTypography, formatNumber, formatNumberToCurrency } from 'ui';
import { Divider, Stack, Typography } from '@mui/material';

type Legend = {
  label: string,
  color: string,
  value: number,
  count: number,
};

export const DonutChartLegends = ({ legends }: { legends: Legend[] }) => (
  <>
    {legends.map((legend, i) => (
      <Fragment key={`revenue-at-risk-legend-${legend.label}`}>
        {i !== 0 && <Divider sx={{ my: 1 }} />}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={5}
          flexWrap="nowrap"
        >
          <Stack direction="row" alignItems="center" gap={3} flexWrap="nowrap">
            <Stack
              height={10}
              width={10}
              borderRadius={100}
              sx={{
                background: legend.color,
              }}
            />
            <Typography variant="caption" color="text.secondary">{legend.label}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={10} flexWrap="nowrap">
            <BoldTypography variant="body2">
              {formatNumber(legend.count, 0)}
            </BoldTypography>
            <BoldTypography variant="body2" sx={{ minWidth: 80, textAlign: 'right' }}>
              {formatNumberToCurrency(legend.value, 0)}
            </BoldTypography>
          </Stack>
        </Stack>
      </Fragment>
    ))}
  </>
);
