export const listOwnerLeads = `
    query MyQuery($pm: String!) {
      listOwnerLeadWorkflowItemsByPm(pm: $pm) {
        items {
          updatedTime
          numOfProperties
          whatAreYouLookingFor
          status
          ownerLead {
            email
            firstName
            lastName
            phoneNumber
          }
        }
      }
    }
`;

export const listOwnerLeadReferralLinks = `
    query MyQuery($ownerId: String!) {
      listOwnerLeadReferralLinks(filter: {ownerId: {eq: $ownerId}}) {
        items {
          id
        }
      }
    }
`;
