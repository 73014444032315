import { ReactNode } from 'react';

import {
  BoldTypography, InvertedButton, SemiBoldTypography, useLabels,
} from 'ui';
import { Stack } from '@mui/material';

export const BlurredComponent = ({
  title,
  subtitle,
  unlockText = undefined,
  onUnlock = undefined,
  isBlurred,
  children,
}: {
  title: string,
  subtitle: string,
  onUnlock?: () => void,
  unlockText?: string,
  isBlurred: boolean,
  children: ReactNode,
}) => {
  const l = useLabels();

  if (!isBlurred) return children;

  return (
    <Stack position="relative" height="100%">
      <Stack
        gap={3}
        height="100%"
        width="100%"
        borderRadius="6px"
        p={7}
        alignItems="center"
        justifyContent="center"
        position="absolute"
        top={0}
        left={0}
        zIndex={1000}
      >
        <BoldTypography variant="h6" sx={{ textAlign: 'center' }}>{title}</BoldTypography>
        <SemiBoldTypography variant="body2" sx={{ textAlign: 'center' }}>{subtitle}</SemiBoldTypography>
        {onUnlock && <InvertedButton onClick={onUnlock}>{unlockText ?? l.unlock}</InvertedButton>}
      </Stack>
      <Stack sx={{ filter: 'blur(8px)', height: '100%' }}>
        {children}
      </Stack>
    </Stack>
  );
};
