import {
  OwnerReferralsIncentive, useAnalytics,
} from 'lib';
import {
  BoldTypography, InvertedButton, recommendationBackgroundURL, ReferAndEarn,
  useLabels,
} from 'ui';
import { Stack, Typography, useTheme } from '@mui/material';

export const ReferAnOwnerButton = ({ onClick, ownerReferralsIncentive }: { onClick: () => void,
  ownerReferralsIncentive: OwnerReferralsIncentive }) => {
  const analytics = useAnalytics();
  const theme = useTheme();

  const l = useLabels();

  const ownerReferralsIncentiveToText = {
    [OwnerReferralsIncentive.NO_INCENTIVE]: l['referAnOwner.referralButtonText.noIncentive'],
    [OwnerReferralsIncentive.ZERO_MGMT_FEE_ONE_MONTH]: l['referAnOwner.referralButtonText.oneMonth'],
    [OwnerReferralsIncentive.ZERO_MGMT_FEE_TWO_MONTHS]: l['referAnOwner.referralButtonText.twoMonths'],
    [OwnerReferralsIncentive.ZERO_MGMT_FEE_THREE_MONTHS]: l['referAnOwner.referralButtonText.threeMonths'],
  } as const;

  return (
    <Stack
      height="174px"
      mb={2}
      py={2}
      direction="row"
      justifyContent="space-between"
      px={3}
      mx={4}
      borderRadius="10px"
      sx={{
        background: `url(${recommendationBackgroundURL}) no-repeat`,
        backgroundSize: 'cover',
      }}
    >
      <Stack direction="column" gap={3}>
        <Stack direction="row" alignItems="center" gap={3}>
          <ReferAndEarn height={40} width={50} />
          <BoldTypography variant="body1">{l['referAnOwner.referAndEarn']}</BoldTypography>
        </Stack>
        <Typography
          variant="body3"
          sx={{
            width: '100%',
          }}
        >
          {
            ownerReferralsIncentiveToText[ownerReferralsIncentive]
          }
          {ownerReferralsIncentive !== OwnerReferralsIncentive.NO_INCENTIVE
              && <span style={{ color: theme.palette.primary.dark }}>{l['referAnOwner.referralButtonText.free']}</span>}
        </Typography>
        <InvertedButton onClick={() => {
          onClick();
          analytics.track('Button Clicked', {
            buttonName: 'Refer An Owner',
          });
        }}
        >
          {l['referAnOwner.referAClient']}
        </InvertedButton>
      </Stack>

    </Stack>
  );
};
