import { useState } from 'react';

import {
  APIUser as User, useAnalytics, useImpersonate,
  useInviteUser,
} from 'lib';
import {
  MdMoreHoriz, MdOutlineEmail, MdOutlineLogin, MdOutlineSms,
  MdPlayCircleOutline,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  InvertedButton, useLabels, YesOrNoLayout,
} from 'ui';
import {
  Button, DialogContent, IconButton,
  Menu, Modal, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { CellContext } from '@tanstack/react-table';

import { QuestionnaireDialog } from './performance-review/QuestionnaireDialog';

export const InviteUserButton = ({ user, smsText, onClose = () => { } }:
{ user: User, smsText: string, onClose?: () => void }) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const { isLoading, mutateAsync } = useInviteUser();
  const [confirmationEmailOpen, setConfirmationEmailOpen] = useState(false);
  const [confirmationSMSOpen, setConfirmationSMSOpen] = useState(false);

  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  const handleSubmit = async (communicationOption: 'EMAIL' | 'SMS') => {
    analytics.track('Button Clicked', {
      buttonName: 'Invite Owner',
      ownerEmail: user.email,
      communicationOption,
    });

    try {
      await mutateAsync({ email: user.email, communicationOption });
      setConfirmationEmailOpen(false);
      setConfirmationSMSOpen(false);
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  const confirmationModalEmail = (
    <Modal
      open={confirmationEmailOpen}
      onClose={() => setConfirmationEmailOpen(false)}
      sx={{
        zIndex: 1800,
      }}
    >
      <DialogContent>
        <YesOrNoLayout
          isLoading={isLoading}
          toggle={() => setConfirmationEmailOpen((c) => !c)}
          onSubmit={() => handleSubmit('EMAIL')}
          title={l.areYouSure}
          description={l['pm-dashboard.owner.confirmation']}
        />
      </DialogContent>
    </Modal>
  );

  const confirmationModalSMS = (
    <Modal
      open={confirmationSMSOpen}
      onClose={() => setConfirmationSMSOpen(false)}
      sx={{
        zIndex: 1800,
      }}
    >
      <DialogContent>
        <YesOrNoLayout
          isLoading={isLoading}
          toggle={() => setConfirmationSMSOpen((c) => !c)}
          onSubmit={() => handleSubmit('SMS')}
          title={`The following message will be sent to ${user.email}`}
          // eslint-disable-next-line react/no-danger
          description={<span dangerouslySetInnerHTML={{ __html: smsText }} />}
        />
      </DialogContent>
    </Modal>
  );

  if (user.isActivated) {
    return null;
  }

  if (isLowerThanMd) {
    return (
      <>
        <Stack spacing={1}>
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              onClose();
              setConfirmationEmailOpen(true);
            }}
          >
            <Typography variant="body2" color="primary.contrastText">
              {l.inviteOwnerByEmail}
            </Typography>
          </Button>
          {!!user.phoneNumber && (
            <Button
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                onClose();
                setConfirmationSMSOpen(true);
              }}
            >
              <Typography variant="body2" color="primary.contrastText">
                {l.inviteOwnerBySMS}
              </Typography>
            </Button>
          )}
        </Stack>
        {confirmationModalEmail}
        {confirmationModalSMS}
      </>
    );
  }

  return (
    <>
      <InvertedButton
        variant="contained"
        size="small"
        startIcon={<MdOutlineEmail />}
        onClick={() => setConfirmationEmailOpen(true)}
      >
        {l.emailInvite}
      </InvertedButton>
      {!!user.phoneNumber && (
        <InvertedButton
          variant="contained"
          size="small"
          startIcon={<MdOutlineSms />}
          onClick={() => setConfirmationSMSOpen(true)}
        >
          {l.smsInvite}
        </InvertedButton>

      )}
      {confirmationModalEmail}
      {confirmationModalSMS}
    </>
  );
};

export const ImpersonateOwnerButton = ({ user }: { user: User }) => {
  const theme = useTheme();
  const l = useLabels();
  const analytics = useAnalytics();
  const { isLoading, mutateAsync } = useImpersonate();

  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  if (!user.isActivated) {
    return null;
  }

  const triggerInviteOwner = async (email: string) => mutateAsync(email);

  const handleClick = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Impersonate Owner',
      ownerEmail: user.email,
    });

    try {
      const impersonationToken = await triggerInviteOwner(user.email);
      window.open(`/?impersonation-token=${impersonationToken.data.token}`, '_blank');
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  if (isLowerThanMd) {
    return (
      <Button variant="contained" onClick={handleClick}>
        <Typography variant="body2" color="primary.contrastText">
          {l.loginAs}
        </Typography>
      </Button>
    );
  }

  return (
    <InvertedButton
      disabled={isLoading}
      variant="contained"
      size="small"
      startIcon={<MdOutlineLogin />}
      onClick={handleClick}
    >
      {l.view}
    </InvertedButton>
  );
};

export const PerformanceReviewButton = ({ user }: { user: User }) => {
  const theme = useTheme();
  const l = useLabels();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  if (!user.isActivated) {
    return null;
  }

  return (
    <>
      {isLowerThanMd ? (
        <Button variant="contained" onClick={handleClick}>
          <Typography variant="body2" color="primary.contrastText">
            {l.performanceReview}
          </Typography>
        </Button>
      ) : (
        <InvertedButton variant="contained" size="small" startIcon={<MdPlayCircleOutline />} onClick={handleClick}>
          {l.review}
        </InvertedButton>
      )}
      <QuestionnaireDialog open={open} setOpen={setOpen} user={user} />
    </>
  );
};

export const RowButtons = ({ info, smsText }: { info: CellContext<User, string>, smsText: string }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClick}><MdMoreHoriz /></IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        keepMounted
        onClose={handleClose}
        MenuListProps={{ sx: { p: 2 } }}
      >
        <Stack spacing={1}>
          <InviteUserButton
            user={info.row.original}
            smsText={smsText}
            onClose={handleClose}
          />
          <ImpersonateOwnerButton
            user={info.row.original}
          />
          <PerformanceReviewButton user={info.row.original} />
        </Stack>
      </Menu>
    </>
  );
};
