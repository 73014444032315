import { useLocation, useNavigate } from 'react-router-dom';

import {
  OwnerRiskStatus,
  useFeatures,
} from 'lib';
import {
  CollapsibleNavMenu, CollapsibleNavMenuItem,
  Home, MenuItem, NetworkIcon, NewGrowIcon, NewOpportunitiesIcon,
  RetainIcon, useLabels,
} from 'ui';

import { useGetOwnerRisksByPM } from '../../api/owner-risk';
import { useGetNewSuggestedPartnerCount } from '../../api/suggested-partners';

const menuIconSize = 18;
const subMenuIconSize = 16;

export const paths = {
  home: '/pm',
  properties: '/pm/properties',
  contacts: '/pm/contacts',
  leads: '/pm/leads',
  retain: '/pm/retain',
  grow: '/pm/grow/opportunities',
  growNetwork: '/pm/grow/network',
} as const;

const maxSuggestedPartnerCount = 50;

export const MenuItems = ({ onNavigate = () => { } }: { onNavigate: () => void }) => {
  const l = useLabels();
  const location = useLocation();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();
  const {
    data: suggestedPartnerCount,
    isLoading: isLoadingSuggestedPartnerCount,
  } = useGetNewSuggestedPartnerCount();
  const {
    data: ownerRisksPages,
    isLoading: isLoadingOwnerRisks,
    hasNextPage: hasNextPageOwnerRisks,
  } = useGetOwnerRisksByPM();

  const ownerRiskCount = ownerRisksPages?.pages.flatMap((page) => page.ownerRisks)
    .filter((p) => p.status === OwnerRiskStatus.NEW && !p.isNoRisk).length ?? 0;

  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    onNavigate();
    navigate(path);
  };

  const growActive = [
    location.pathname.startsWith(paths.grow),
    location.pathname.startsWith(paths.growNetwork),
  ].includes(true);

  if (isLoadingFeatures) return null;

  return (
    <>
      {features.isPmAppEnabled && (
        <MenuItem
          icon={<Home height={menuIconSize} width={menuIconSize} />}
          label={l['menu-blanket-overview']}
          onClick={() => handleNavigate(paths.home)}
          isActive={location.pathname === paths.home}
        />
      )}
      {features.isRetainProductEnabled && (
        <MenuItem
          icon={<RetainIcon height={subMenuIconSize} width={subMenuIconSize} />}
          label={l.retain}
          onClick={() => handleNavigate(paths.retain)}
          isActive={location.pathname === paths.retain}
          chipContent={!isLoadingOwnerRisks && !hasNextPageOwnerRisks && (ownerRiskCount || undefined)}
        />
      )}
      {features.isGrowProductEnabled && (
        <CollapsibleNavMenu
          initialOpen={growActive}
          icon={<NewGrowIcon height={menuIconSize} width={menuIconSize} />}
          label={l.grow}
          isActive={growActive}
        >
          <CollapsibleNavMenuItem
            icon={<NewOpportunitiesIcon height={menuIconSize} width={menuIconSize} />}
            text={l.opportunities}
            onNavigate={() => handleNavigate(paths.grow)}
            isActive={location.pathname === paths.grow}
            chipContent={!isLoadingSuggestedPartnerCount && (
              (suggestedPartnerCount ?? 0) > maxSuggestedPartnerCount
                ? `${maxSuggestedPartnerCount}+`
                : suggestedPartnerCount || undefined
            )}
          />
          <CollapsibleNavMenuItem
            icon={<NetworkIcon height={subMenuIconSize} width={subMenuIconSize} />}
            text={l.network}
            onNavigate={() => handleNavigate(paths.growNetwork)}
            isActive={location.pathname === paths.growNetwork}
          />
        </CollapsibleNavMenu>
      )}
    </>
  );
};
