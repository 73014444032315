import { ChangeEvent, useEffect, useState } from 'react';

import {
  QueryKey, useAnalytics, useAuth, useImportUsers, UserImportRequest,
} from 'lib';
import { toast } from 'react-toastify';
import {
  BoldTypography,
  useLabels,
} from 'ui';
import {
  Box, Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  keyframes, MenuItem,
  Select,
  Stack, styled, TextField,
  Typography,
  useMediaQuery, useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export type UserImport = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

export type ValidateUserImport = {
  isValid: boolean;
  message: string;
  user: UserImport
};

export const FooterActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3),
  padding: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.divider}`,
  bottom: '-1px',
  background: theme.palette.background.paper,
  zIndex: '1600',
  '&.sticky': {
    position: 'sticky',
    animation: `${fadeIn} 0.3s ease-in-out`,
  },
}));

export const CreateTeamMemberDialog = ({ open, closeDialog }: { open: boolean, closeDialog: () => void, }) => {
  const theme = useTheme();
  const l = useLabels();
  const { mutateAsync } = useImportUsers();
  const { user } = useAuth();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();

  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);

  const validateStringNotEmpty = (name: string) => name.trim().length > 0;

  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const lastNameInput = e.target.value;
    setLastName(lastNameInput);
    setLastNameError(!validateStringNotEmpty(lastNameInput));
  };

  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const firstNameInput = e.target.value;
    setFirstName(firstNameInput);
    setFirstNameError(!validateStringNotEmpty(firstNameInput));
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const emailInput = e.target.value;
    setEmail(emailInput);
    setEmailError(!emailValidationRegex.test(emailInput));
  };

  const invite = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Invite team member clicked',
      ownerEmail: email,
      userType: selectedRole.toLowerCase(),
      propertyManager: user?.pm ?? '',
      firstName,
      lastName,
      phoneNumber,
    });

    const userToInvite: UserImportRequest = {
      email,
      firstName,
      lastName,
      phoneNumber,
      propertyManager: user?.pm ?? '',
      companyRole: selectedRole.toLowerCase(),
      userType: selectedRole.toLowerCase(),
    };

    try {
      await mutateAsync({ users: [userToInvite] });
      queryClient.invalidateQueries([QueryKey.USERS]);
      toast.success(l['pm-dashboard.teamMember.messageSuccess']);
    } catch (error) {
      toast.error(l['error.unknownError']);
    }

    closeDialog();
  };

  useEffect(() => {
    if (!open) {
      setEmail('');
      setFirstName('');
      setLastName('');
      setPhoneNumber('');
      setSelectedRole('');
      setEmailError(false);
      setFirstNameError(false);
      setLastNameError(false);
    }
  }, [open]);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog
      fullScreen={isLowerThanMd}
      open={open}
      maxWidth="sm"
      sx={{ zIndex: 1600 }}
    >
      <DialogContent sx={{ p: 0 }}>
        <Box
          p={4}
          position="relative"
          flexGrow="1"
          minHeight={isLowerThanMd ? '100%' : '480px'}
          width={isLowerThanMd ? undefined : theme.breakpoints.values.sm}
        >
          <Stack spacing={4}>
            <Stack
              justifyContent="flex-start"
              alignItems="left"
              spacing={1}
            >
              <Stack>
                <BoldTypography>
                  {l['pm-dashboard.teamMember.inviteTeamMember']}
                </BoldTypography>
              </Stack>
              <Stack>
                <Typography>
                  {l['pm-dashboard.teamMember.inviteDescription']}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              height="150px"
              spacing={5}
            >
              <TextField
                label="First Name"
                variant="outlined"
                value={firstName}
                onChange={handleFirstNameChange}
                error={firstNameError}
                helperText={firstNameError ? l['pm-dashboard.teamMember.enterValidFirstName'] : ''}
              />

              <TextField
                label="Last Name"
                variant="outlined"
                value={lastName}
                onChange={handleLastNameChange}
                error={lastNameError}
                helperText={lastNameError ? l['pm-dashboard.teamMember.enterValidLastName'] : ''}
              />

              <FormControl fullWidth>
                <InputLabel id="team-member-role-label">Role</InputLabel>
                <Select
                  labelId="team-member-role-label"
                  value={selectedRole}
                  label="Role"
                  onChange={(e) => setSelectedRole(e.target.value)}
                  onOpen={() => setIsOpen(true)}
                  onClose={() => setIsOpen(false)}
                >
                  <MenuItem value="admin">
                    <Stack direction="column" spacing={2}>
                      <Typography variant="body1">Admin</Typography>
                      {isOpen && (
                        <Typography variant="body3">
                          Designated user for Company Owners
                        </Typography>
                      )}
                    </Stack>
                  </MenuItem>
                  <MenuItem value="portfolio_manager">
                    <Stack direction="column" spacing={2}>
                      <Typography variant="body1">Portfolio Manager</Typography>
                      {isOpen && (
                        <>
                          <Typography variant="body3" width="100%">
                            Designated user for Property Managers and Team Members
                          </Typography>
                          <Typography variant="body3" width="100%">
                            responsible for owner communications
                          </Typography>
                        </>
                      )}
                    </Stack>
                  </MenuItem>
                  <MenuItem value="sale_manager">
                    <Stack direction="column" spacing={2}>
                      <Typography variant="body1">Sales Manager</Typography>
                      {isOpen && (
                        <>
                          <Typography variant="body3">
                            Designated user for BDMs, Agents, and Team Members responsible
                          </Typography>
                          <Typography variant="body3">
                            for owner acquisition and real estate sales
                          </Typography>
                        </>
                      )}
                    </Stack>
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Email"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                error={emailError}
                helperText={emailError ? l['pm-dashboard.teamMember.enterValidEmail'] : ''}
              />
              <TextField
                label="Phone Number"
                variant="outlined"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Stack>
          </Stack>
        </Box>
        <FooterActions className="sticky">
          <Button
            onClick={closeDialog}
            variant="text"
            sx={{ py: 3, color: theme.palette.text.primary }}
          >
            {l.cancel}
          </Button>
          <Button
            variant="contained"
            disabled={email === '' || emailError || selectedRole === '' || firstName === '' || lastName === ''}
            sx={{ py: 3 }}
            onClick={invite}
          >
            {l.invite}
          </Button>
        </FooterActions>
      </DialogContent>
    </Dialog>
  );
};
