import { useEffect } from 'react';

import dayjs from 'dayjs';
import { ProspectLeadsWorkflowStatus, ProspectLeadWorkflowItem, useAnalytics } from 'lib';
import { toast } from 'react-toastify';
import {
  ColumnCard,
  Columns, FallbackSpinner, KanbanBoard, useLabels,
} from 'ui';
import { Stack } from '@mui/material';
import { signal } from '@preact/signals-react';

import { ColCard } from './ColCard';
import { useListProspectLeads, useMutateProspectLead } from '../../../api/workflows';
import { LeadCardHeader } from '../components/LeadCardHeader';
import { StatusChanger } from '../components/StatusChanger';
import { moveCardToColumn, updateCard } from '../utils';
import { useProspectLeadStatuses } from '../WorkflowStatus';

const columnsSignal = signal<Columns<ProspectLeadCard>>({});

export const ProspectLeadsKanban = ({ orderedStatuses }: { orderedStatuses: ProspectLeadsWorkflowStatus[] }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { getStatus } = useProspectLeadStatuses();
  const { data, isLoading, isError } = useListProspectLeads();
  const { mutateAsync } = useMutateProspectLead();

  useEffect(() => {
    if (data) {
      const cols: Columns<ProspectLeadCard> = {};

      orderedStatuses.forEach((columnID) => {
        const status = getStatus(columnID);

        cols[columnID] = {
          id: columnID,
          icon: status.icon,
          title: status.displayValue,
          color: status.color,
          tooltip: status.tooltip,
          cards: [],
        };
      });

      data.pages.forEach((page) => {
        page.leads.forEach((lead) => {
          const columnID = lead.status;

          cols[columnID].cards.push({
            ...lead,
            originalColumnID: columnID,
            disabled: false,
            disableStatusChange: true,
          });
        });
      });

      Object.keys(cols).forEach((columnID) => {
        cols[columnID].cards.sort((a, b) => dayjs(b.updatedTime).diff(dayjs(a.updatedTime)));
      });

      columnsSignal.value = cols;
    }

    if (isError) toast.error(l['error.unknownError']);
  }, [data, isLoading, isError, columnsSignal]);

  const handleColumnChange = async (lead: ProspectLeadCard, newStatus: string) => {
    analytics.track('Card Moved', {
      cardName: 'Prospect Lead',
      from: lead.status,
      to: newStatus,
      id: lead.id,
    });

    const oldStatus = lead.status;

    columnsSignal.value = updateCard(columnsSignal.value, {
      ...lead,
      disabled: true,
    });

    try {
      await mutateAsync({
        id: lead.id,
        status: newStatus as ProspectLeadsWorkflowStatus,
      });
      columnsSignal.value = updateCard(columnsSignal.value, {
        ...lead,
        disabled: false,
        status: newStatus as ProspectLeadsWorkflowStatus,
        originalColumnID: newStatus as ProspectLeadsWorkflowStatus,
      });
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);

      columnsSignal.value = moveCardToColumn(columnsSignal.value, {
        ...lead,
        disabled: false,
        status: oldStatus,
        originalColumnID: oldStatus!,
      });
    }
  };

  useEffect(() => {
    document.querySelector('html')?.classList.add('full-height');

    return () => {
      document.querySelector('html')?.classList.remove('full-height');
    };
  }, []);

  if (isLoading) {
    return <FallbackSpinner />;
  }

  return (
    <Stack flexGrow={1} height="100%">
      <KanbanBoard
        colWidth={250}
        orderedColumnIDs={orderedStatuses}
        CardComponent={ProspectLeadColCard}
        columnsSignal={columnsSignal}
        onColumnChange={handleColumnChange}
      />
    </Stack>
  );
};

type ProspectLeadCard = ProspectLeadWorkflowItem & ColumnCard;

const ProspectLeadColCard = ({
  card,
  isOverlay = false,
}: {
  card: ProspectLeadCard,
  isOverlay?: boolean,
}) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { statuses } = useProspectLeadStatuses();
  const { mutateAsync: reveal, isLoading: isRevealing } = useMutateProspectLead();
  const { mutateAsync: updateStatus, isLoading: isUpdatingStatus } = useMutateProspectLead();

  const revealLead = async () => {
    await reveal({
      id: card.id,
      revealed: true,
    });
    columnsSignal.value = updateCard(columnsSignal.value, {
      ...card,
      revealed: true,
    });
  };
  const handleStatusChange = async (status: ProspectLeadsWorkflowStatus) => {
    analytics.track('Selector Changed', {
      selectorName: 'Lead Status',
      value: status,
    });

    try {
      await updateStatus({
        id: card.id,
        status,
      });
      columnsSignal.value = moveCardToColumn(columnsSignal.value, {
        ...card,
        disabled: false,
        status,
        originalColumnID: status,
      });
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);
    }
  };

  return (
    <ColCard
      card={card}
      revealCard={revealLead}
      revealed={!!card.revealed}
      isRevealing={isRevealing}
      createdTime={card.createdTime}
      updatedTime={card.updatedTime}
      isOverlay={isOverlay}
      footer={null}
      afterContent={(
        <StatusChanger
          id={card.id}
          status={card.status}
          statuses={statuses}
          changeStatus={handleStatusChange}
          isLoading={isUpdatingStatus}
        />
      )}
    >
      {card.lead && (
        <LeadCardHeader
          firstName={card.lead.firstName}
          lastName={card.lead.lastName}
          email={card.lead.email}
        />
      )}
    </ColCard>
  );
};
