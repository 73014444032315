import { ReactNode, useEffect } from 'react';

import { useAnalytics } from 'lib';
import { UseFormReturn } from 'react-hook-form';
import { Spacer, useLabels } from 'ui';
import {
  Button, ButtonGroup, Collapse, Stack, Typography,
} from '@mui/material';

import { GrowthPolicyData } from '../../api/suggested-partners/forms';

type BooleanFieldName = (
  'enableOwnerReferralProgram' |
  'enablePMReferralProgram'
);

export const GrowthPolicyBooleanField = ({
  name,
  form,
  yesLabel = undefined,
  noLabel = undefined,
  collapsibleContent = undefined,
  showCollapsibleContent = false,
  invert = false,
}: {
  name: BooleanFieldName,
  form: UseFormReturn<GrowthPolicyData>,
  yesLabel?: string,
  noLabel?: string,
  collapsibleContent?: ReactNode,
  showCollapsibleContent?: boolean,
  invert?: boolean,
}) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const checked = form.watch(name);
  const handleChange = (value: boolean) => {
    analytics.track('Checkbox Toggled', {
      checkboxName: name,
      value,
    });

    form.setValue(name, value);
  };

  const yesButton = (
    <Button
      onClick={() => handleChange(true)}
      variant={checked ? 'contained' : 'outlined'}
    >
      {yesLabel ?? l.yes}
    </Button>
  );

  const noButton = (
    <Button
      onClick={() => handleChange(false)}
      variant={checked === false ? 'contained' : 'outlined'}
    >
      {noLabel ?? l.no}
    </Button>
  );

  useEffect(() => {
    if (checked !== null) form.trigger(name);
  }, [checked, form, name]);

  return (
    <Stack justifyContent="center">
      {invert ? (
        <ButtonGroup color="primary" fullWidth>
          {noButton}
          {yesButton}
        </ButtonGroup>
      ) : (
        <ButtonGroup color="primary" fullWidth>
          {yesButton}
          {noButton}
        </ButtonGroup>
      )}
      {form.formState.errors[name]?.message && (
        <Typography variant="body2" color="error.main" sx={{ mt: 1, pl: 3.5 }}>
          {form.formState.errors[name]?.message}
        </Typography>
      )}
      <Collapse in={showCollapsibleContent}>
        <Spacer spacing={4} />
        {collapsibleContent}
      </Collapse>
    </Stack>
  );
};
