import { Permissions, usePermissions } from './useAuth';
import {
  useIsB2CReferralProgramEnabledFF,
  useIsGrowProductEnabledFF,
  useIsLegacyPM, useIsPmPropertiesDashboardEnabledFF, useIsRetainProductEnabledFF,
} from './useFeatureFlag';

type Feature = {
  isEnabled: boolean,
  isLoading: boolean,
};

export type Features = {
  isLoading: boolean,
  isOwnerDashboardEnabled: boolean,
  isMarketplaceEnabled: boolean,
  isGrowProductEnabled: boolean,
  isRetainProductEnabled: boolean,
  isPmLeadsViewEnabled: boolean,
  isPmReferralLeadsEnabled: boolean,
  isMarketplaceLeadsViewEnabled: boolean,
  isObpEnabled: boolean,
  isPmAppEnabled: boolean,
  isPmPropertiesDashboardEnabled: boolean,
  isReferralPartnersAppEnabled: boolean,
  isBillingEnabled: boolean,
  isB2CReferralProgramEnabled: boolean,
  isSettingsViewEnabled: boolean,
  isPmProspectLeadsViewEnabled: boolean,
};

export const useFeatures = (): Features => {
  const { data: permissions, isLoading: isLoadingPermissions } = usePermissions();
  const { isEnabled: isMarketplaceEnabled, isLoading: isLoadingMarketplaceEnabled } = useIsMarketplaceEnabled(permissions);
  const { isEnabled: isGrowProductEnabled, isLoading: isLoadingGrowProductEnabled } = useIsGrowProductEnabled(permissions);
  const { isEnabled: isRetainProductEnabled, isLoading: isLoadingRetainProductEnabled } = useIsRetainProductEnabled(permissions);
  const { isEnabled: isPmLeadsViewEnabled, isLoading: isLoadingPmLeadsViewEnabled } = useIsPmLeadsViewEnabled(permissions);
  const { isEnabled: isBillingEnabled, isLoading: isLoadingBillingEnabled } = useIsBillingEnabled(permissions);
  const { isEnabled: isObpEnabled, isLoading: isLoadingObpEnabled } = useIsObpEnabled(permissions);
  const { isEnabled: isPmAppEnabled, isLoading: isLoadingPmAppEnabled } = useIsPmAppEnabled(permissions);
  const {
    isEnabled: isPmProspectLeadsViewEnabled,
    isLoading: isLoadingPmProspectLeadsViewEnabled,
  } = useIsPmProspectLeadsViewEnabled(permissions);
  const {
    isEnabled: isPmReferralLeadsEnabled,
    isLoading: isLoadingPmReferralLeadsEnabled,
  } = useIsPmReferralLeadsEnabled(permissions);
  const {
    isEnabled: isOwnerDashboardEnabled,
    isLoading: isLoadingOwnerDashboardEnabled,
  } = useIsOwnerDashboardEnabled(permissions);
  const {
    isEnabled: isMarketplaceLeadsViewEnabled,
    isLoading: isLoadingMarketplaceLeadsViewEnabled,
  } = useIsMarketplaceLeadsViewEnabled(permissions);
  const {
    isEnabled: isPmPropertiesDashboardEnabled,
    isLoading: isLoadingPmPropertiesDashboardEnabled,
  } = useIsPmPropertiesDashboardEnabled(permissions);
  const {
    isEnabled: isReferralPartnersAppEnabled,
    isLoading: isLoadingReferralPartnersAppEnabled,
  } = useIsReferralPartnersAppEnabled(permissions);
  const {
    isEnabled: isB2CReferralProgramEnabled,
    isLoading: isLoadingB2CReferralProgramEnabled,
  } = useIsB2CReferralProgramEnabled(permissions);

  const isLoading = [
    isLoadingPermissions, isLoadingOwnerDashboardEnabled, isLoadingMarketplaceEnabled, isLoadingGrowProductEnabled,
    isLoadingRetainProductEnabled, isLoadingPmLeadsViewEnabled, isLoadingMarketplaceLeadsViewEnabled,
    isLoadingObpEnabled, isLoadingPmAppEnabled, isLoadingPmPropertiesDashboardEnabled,
    isLoadingReferralPartnersAppEnabled, isLoadingBillingEnabled, isLoadingPmProspectLeadsViewEnabled,
    isLoadingB2CReferralProgramEnabled, isLoadingPmReferralLeadsEnabled,
  ].some((f) => !!f);

  return {
    isLoading,
    isOwnerDashboardEnabled,
    isMarketplaceEnabled,
    isGrowProductEnabled,
    isRetainProductEnabled,
    isPmLeadsViewEnabled,
    isPmReferralLeadsEnabled,
    isMarketplaceLeadsViewEnabled,
    isObpEnabled,
    isPmAppEnabled,
    isPmPropertiesDashboardEnabled,
    isReferralPartnersAppEnabled,
    isBillingEnabled,
    isB2CReferralProgramEnabled,
    isPmProspectLeadsViewEnabled,
    isSettingsViewEnabled: isPmAppEnabled || isBillingEnabled,
  };
};

const useIsOwnerDashboardEnabled = (permissions: Permissions | undefined): Feature => {
  const { value: isLegacyPM, loading: loadingIsLegacyPM } = useIsLegacyPM();
  const { value: isRetainProductEnabledFF, loading: isLoadingRetainProductFF } = useIsRetainProductEnabledFF();

  const isLoading = loadingIsLegacyPM || isLoadingRetainProductFF;

  return {
    isEnabled: (isLegacyPM || isRetainProductEnabledFF) && !!permissions?.viewDashboard,
    isLoading,
  };
};

const useIsMarketplaceEnabled = (permissions: Permissions | undefined): Feature => {
  const { value: isLegacyPM, loading: loadingIsLegacyPM } = useIsLegacyPM();
  const { value: isGrowthEnabled, loading: isLoadingGrowth } = useIsGrowProductEnabledFF();

  const legacyMarketplaceEnabled = isLegacyPM && permissions?.viewMarketplace;
  const isMarketplaceEnabled = isGrowthEnabled && permissions?.viewMarketplace;
  const isLoading = (
    loadingIsLegacyPM || isLoadingGrowth
  );

  return {
    isEnabled: legacyMarketplaceEnabled || isMarketplaceEnabled,
    isLoading,
  };
};

const useIsGrowProductEnabled = (permissions: Permissions | undefined): Feature => {
  const { value: isGrowthProductEnabled, loading: isLoadingGrowthProductFF } = useIsGrowProductEnabledFF();

  const isEnabled = isGrowthProductEnabled && permissions?.viewGrow;
  const isLoading = (
    isLoadingGrowthProductFF
  );

  return {
    isEnabled,
    isLoading,
  };
};

const useIsRetainProductEnabled = (permissions: Permissions | undefined): Feature => {
  const { value: isRetainProductEnabled, loading: isLoadingRetainProductFF } = useIsRetainProductEnabledFF();

  const isEnabled = isRetainProductEnabled && permissions?.viewRetain;
  const isLoading = (
    isLoadingRetainProductFF
  );

  return {
    isEnabled,
    isLoading,
  };
};

const useIsPmLeadsViewEnabled = (permissions: Permissions | undefined): Feature => {
  const { isEnabled: isMarketplaceEnabled, isLoading: loadingIsMarketplaceEnabled } = useIsMarketplaceEnabled(permissions);

  const isLoading = loadingIsMarketplaceEnabled;

  return {
    isEnabled: isMarketplaceEnabled && !!permissions?.viewLeads,
    isLoading,
  };
};

const useIsMarketplaceLeadsViewEnabled = (permissions: Permissions | undefined): Feature => {
  const { isEnabled: isMarketplaceEnabled, isLoading: loadingIsMarketplaceEnabled } = useIsMarketplaceEnabled(permissions);
  const { isEnabled: isPmLeadsViewEnabled, isLoading: loadingIsPmLeadsViewEnabled } = useIsPmLeadsViewEnabled(permissions);

  const isLoading = loadingIsPmLeadsViewEnabled || loadingIsMarketplaceEnabled;

  return {
    isEnabled: isMarketplaceEnabled && !isPmLeadsViewEnabled,
    isLoading,
  };
};

const useIsObpEnabled = (permissions: Permissions | undefined): Feature => ({
  isEnabled: !!permissions?.viewOBP,
  isLoading: false,
});

const useIsPmAppEnabled = (permissions: Permissions | undefined): Feature => {
  const { isEnabled: isRetainAppEnabled, isLoading: loadingIsRetainAppEnabled } = useIsRetainProductEnabled(permissions);
  const { isEnabled: isGrowAppEnabled, isLoading: loadingIsGrowAppEnabled } = useIsGrowProductEnabled(permissions);
  const { value: isLegacyPM, loading: loadingIsLegacyPM } = useIsLegacyPM();

  const isLoading = (
    loadingIsRetainAppEnabled || loadingIsGrowAppEnabled || loadingIsLegacyPM
  );

  const isEnabled = permissions?.viewPMDashboard && (
    isRetainAppEnabled || isGrowAppEnabled || isLegacyPM
  );

  return {
    isEnabled,
    isLoading,
  };
};

const useIsPmPropertiesDashboardEnabled = (permissions: Permissions | undefined): Feature => {
  const { value: isLegacyPM, loading: loadingIsLegacyPM } = useIsLegacyPM();
  const {
    value: isPmPropertiesDashboardEnabled,
    loading: loadingIsPmPropertiesDashboardEnabled,
  } = useIsPmPropertiesDashboardEnabledFF();
  const { isEnabled: isRetainProductEnabled, isLoading: loadingIsRetainProductEnabled } = useIsRetainProductEnabled(permissions);

  const isLoading = (
    loadingIsLegacyPM || loadingIsPmPropertiesDashboardEnabled || loadingIsRetainProductEnabled
  );

  return {
    isEnabled: (isLegacyPM && isPmPropertiesDashboardEnabled) || isRetainProductEnabled,
    isLoading,
  };
};

const useIsReferralPartnersAppEnabled = (permissions: Permissions | undefined): Feature => ({
  isEnabled: !!permissions?.viewReferralPartner,
  isLoading: false,
});

const useIsBillingEnabled = (permissions: Permissions | undefined): Feature => ({
  isEnabled: !!permissions?.viewBilling,
  isLoading: false,
});

const useIsB2CReferralProgramEnabled = (permissions: Permissions | undefined): Feature => {
  const { value: b2cProgramFFEnabled, loading: isB2cProgramFFLoading } = useIsB2CReferralProgramEnabledFF();

  const isLoading = isB2cProgramFFLoading;

  return {
    isEnabled: b2cProgramFFEnabled && !!permissions?.viewB2CReferralProgram,
    isLoading,
  };
};

export const useIsPmReferralLeadsEnabled = (permissions: Permissions | undefined): Feature => {
  const { value: isLegacyPM, loading: loadingIsLegacyPM } = useIsLegacyPM();

  return {
    isEnabled: !isLegacyPM && !!permissions?.viewReferralLeads,
    isLoading: loadingIsLegacyPM,
  };
};

export const useIsPmProspectLeadsViewEnabled = (permissions: Permissions | undefined): Feature => {
  const { isEnabled: isGrowthProductEnabled, isLoading: loadingIsGrowthProductEnabled } = useIsGrowProductEnabled(permissions);

  return {
    isEnabled: isGrowthProductEnabled && !!permissions?.viewProspectLeads,
    isLoading: loadingIsGrowthProductEnabled,
  };
};
